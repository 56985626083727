<template>
  <div style="display: flex; flex-direction: column; height: calc(100vh - 180px)">
    <div style="display: flex; align-items: center">
      <div class="condition-item">
        <label class="fn-14">所属栏目</label>
        <el-select
          style="width: 120px"
          clearable
          v-model="searchData.cid"
          placeholder="所属栏目"
        >
          <el-option
            v-for="item in options"
            :key="item.cid"
            :label="item.title"
            :value="item.cid"
            autocomplete="off"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">新闻标题</label>
        <el-input
          clearable
          v-model="searchData.newTitle"
          placeholder="请输入新闻标题"
          autocomplete="off"
        ></el-input>
      </div>
      <div class="condition-item">
        <el-button type="primary"
        v-if="searchButton"
         style="width: 85px" @click="handleSearch"
          >搜索</el-button
        >
      </div>
      <div class="condition-item">
        <el-button type="success" style="width: 85px"
        v-if="addDataButton"
         @click="AddData">+添加</el-button>
      </div>
    </div>
    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table :data="newsListTableData" border style="width: 100%" height="100%">
        <template #empty>
          <p>
            {{ tableTaskAndLoading == true ? "数据加载中" : "暂无数据" }}
          </p>
        </template>
        <!-- <el-table-column
          prop="studentName"
          label="序号"
          width="150"
          align=""
        ></el-table-column> -->
        <el-table-column
          prop="title"
          label="所属栏目"
          width="120"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="newTitle"
          label="新闻标题"
          min-width="200px"
          align="left"
        ></el-table-column>
        <el-table-column prop="topOrder" label="指定排序" width="80">
          <template slot-scope="scope">
            <el-input
              v-model:value="scope.row.topOrder"
              @change="changesSort(scope.row.aid, scope.row)"
              size="small"
            ></el-input>
          </template>
        </el-table-column>

        <el-table-column prop="showOrder" label="排序" width="80">
          <template slot-scope="scope">
            <el-input
              v-model:value="scope.row.showOrder"
              @change="changesSort(scope.row.aid, scope.row)"
              size="small"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="clickNum" label="点击次数" width="80">
          <template slot-scope="scope">
            <el-input
              v-model:value="scope.row.clickNum"
              @change="changesSort(scope.row.aid, scope.row)"
              size="small"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="addTime" label="创建时间" width="160" align="center">
          <template slot-scope="scope">
            <span>{{ formDateFormat(scope.row.addTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mergenPaymentFlag" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text"
            v-if="editButton"
             @click="AddEdit(scope.row)">修改</el-button>
            <el-button type="text"
            v-if="deleteButton" @click="deleteOne(scope.row.aid)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 上添加网站栏目弹窗 -->

    <el-dialog :title="newsListShowtitle" :visible.sync="newsListShow" width="90%">
      <el-form
        ref="newsListhowData"
        :model="newsListhowData"
        label-width="120px"
        :rules="newsListRules"
      >
        <el-form-item label="所属栏目">
          <el-select
            clearable
            v-model="newsListhowData.cid"
            placeholder="所属栏目"
            style="width: 100%"
          >
            <el-option
              v-for="item in options"
              :key="item.cid"
              :label="item.title"
              :value="item.cid"
              autocomplete="off"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="新闻标题" prop="newTitle">
          <el-input
            v-model="newsListhowData.newTitle"
            type="text"
            placeholder="请输入新闻标题"
          ></el-input>
        </el-form-item>

        <el-form-item label="来源" prop="source">
          <el-input
            v-model="newsListhowData.source"
            type="text"
            placeholder="请输入来源"
          ></el-input>
        </el-form-item>

        <el-form-item label="作者" prop="author">
          <el-input
            v-model="newsListhowData.author"
            type="text"
            placeholder="请输入作者
            "
          ></el-input>
        </el-form-item>
        <el-form-item label="摘要" prop="summary">
          <el-input
            v-model="newsListhowData.summary"
            type="textarea"
            placeholder="请输入摘要"
            :rows="4"
          ></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="articleContent">
          <contextEditor ref="refEditor" @funSuccess="funSuccess" />
        </el-form-item>
        <el-form-item label="置顶" prop="isTop">
          <el-radio-group v-model="newsListhowData.isTop">
            <el-radio :label="1">(选中为显示址)</el-radio>
          </el-radio-group>
          <el-input
            v-model="newsListhowData.topOrder"
            type="text"
            style="width: 260px; margin-left: 20px"
          ></el-input>
        </el-form-item>
        <el-form-item label="点击次数" prop="clickNum">
          <el-input
            v-model="newsListhowData.clickNum"
            type="text"
            placeholder="请输入点击次数"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="showOrder">
          <el-input
            v-model="newsListhowData.showOrder"
            type="text"
            placeholder="请输入排序号"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="handleConfirm"
          :loading="Confirmloading"
          style="margin-left: 20px"
          >保 存
        </el-button>
        <el-button @click="handleCancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import contextEditor from "@/components/common/contextEditor.vue";
import { dateFormat } from "@/utils/date";
import { queryPageButton } from "@/api/permission";


import {
  queryTfCmsArticles,
  updateTfCmsArticle,
  queryTfCmsColumnList,
  addTfCmsArticle,
  deleteTfCmsArticle,
} from "@/api/informationMg";
export default {
  name: "newsList",
  components: {
    contextEditor,
  },
  data() {
    // const validateContactName = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error("联系人不能为空"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      content: "", //编辑器内容
      newsListTableData: [], //列表数据
      tableTaskAndLoading: false,
      searchData: {},
      options: [],
      total: 0,
      totalAmount: 0,
      currentPage: 1,
      pageSize: 20,
      newsListRules: {
        // isSplit: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     validator: validateContactName,
        //   },
        // ],
      },
      addOredit: 0, //修改或新增  0为新增 1为修改
      // 弹窗
      newsListShow: false,
      newsListShowtitle: "添加网站新闻信息",
      newsListhowData: {},
      Confirmloading: false,
      //权限按钮
      searchButton:false,
      addDataButton:false,
      editButton:false,
      deleteButton:false
    };
  },
  created() {
    this.queryData();
    this.queryTfCmsColumnList();
    this.queryButton();

  },
  methods: {
    queryButton() {
      const data = 143;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "searchButton") {
            this.searchButton = true;
          }   if (button.code === "addDataButton") {
            this.addDataButton = true;
          }  if (button.code === "editButton") {
            this.editButton = true;
          }  if (button.code === "deleteButton") {
            this.deleteButton = true;
          } 
        
          
        }
      });
    },
    //设置回返数据
    funSuccess(htmls = "") {
      this.content = htmls;
    },
    // 编辑器
    workVadeUpdate(content) {
      this.content = content;
    },
    queryTfCmsColumnList() {
      queryTfCmsColumnList().then((res) => {
        if (res.code == 0) {
          this.options = res.data;
        }
      });
    },
    queryData() {
      this.tableTaskAndLoading = true;
      let postData = this.searchData;
      queryTfCmsArticles(this.currentPage, this.pageSize, postData).then((res) => {
        if (res.code == 0) {
          this.tableTaskAndLoading = false;
          this.newsListTableData = res.data.content;
          this.total = res.data.totalElements;
        }
      });
    },
    //排序修改
    changesSort(aid, SortData) {
      if (SortData.topOrder || SortData.showOrder || SortData.clickNum) {
        let data = {
          aid: aid,
          topOrder: Number(SortData.topOrder),
          showOrder: Number(SortData.showOrder),
          clickNum: Number(SortData.clickNum),
        };
        updateTfCmsArticle(data)
          .then((res) => {
            if (res.code == 0) {
              this.$notify({
                title: "温馨提示",
                message: res.message,
                type: "success",
              });
            } else {
              this.$notify({
                title: "警告",
                message: res.data.msg,
                type: "warning",
              });
            }
            this.queryData();
          })
          .catch((error) => {
            this.$notify({
              title: "提示",
              message: error,
              type: "warning",
            });
            this.queryData();
          });
      } else {
        this.$notify({
          title: "警告",
          message: "数值不能为空",
          type: "warning",
        });
        this.queryData();
      }
    },

    /*
        添加
         */
    AddData() {
      this.newsListShowtitle = "新增网站新闻信息";
      this.content = "";
      this.newsListShow = true;
      this.newsListhowData = {};
      this.$nextTick(() => {
        if (this.$refs.refEditor != undefined) {
          this.$refs.refEditor.initData("");
        }
      });
    },
    handleSearch() {
      this.currentPage = 1;
      this.queryData();
    },

    /*
        修改
         */
    AddEdit(data) {
      this.newsListShowtitle = "修改网站新闻信息";
      this.addOredit = 1;
      this.newsListShow = true;
      this.newsListhowData = data;
      this.$nextTick(() => {
        if (this.$refs.refEditor != undefined) {
          this.content = data.articleContent;
          this.$refs.refEditor.initData(this.content);
        }
      });
      // this.content = data.articleContent;
      // console.log(this.content)
    },
    handleConfirm() {
      this.Confirmloading = true;
      let postUrl = null;
      if (this.addOredit == 0) {
        postUrl = addTfCmsArticle;
      } else {
        postUrl = updateTfCmsArticle;
      }
      this.newsListhowData.articleContent = this.content;
      postUrl(this.newsListhowData)
        .then((res) => {
          if (res.code == 0) {
            this.$notify({
              title: "温馨提示",
              message: res.message,
              type: "success",
            });
          } else {
            this.$notify({
              title: "警告",
              message: res.data.msg,
              type: "warning",
            });
          }
          this.newsListShow = false;
          this.Confirmloading = false;
          this.queryData();
        })
        .catch((error) => {
          this.$notify({
            title: "提示",
            message: error,
            type: "warning",
          });
          this.newsListShow = false;
          this.Confirmloading = false;
          this.queryData();
        });
      // console.log(this.content);
    },
    handleCancel() {
      this.newsListShow = false;
    },
    /*
        删除
         */
    deleteOne(aid) {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteTfCmsArticle(aid).then((res) => {
          if (res.code == 0) {
            this.$notify({
              title: "温馨提示",
              message: res.message,
              type: "success",
            });
          } else {
            this.$notify({
              title: "警告",
              message: res.data.msg,
              type: "warning",
            });
          }

          this.queryData();
        });
        //
      });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.queryData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryData();
    },
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd HH:MM:SS", new Date(dateValue));
      }
    },
  },
};
</script>

<style scope>
.condition-item {
  margin-right: 10px;
}
</style>
