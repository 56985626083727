<template>
  <div
    style="
      border: 1px solid #ccc;
      margin-top: 10px;
      z-index: 9;
      position: relative;
      width: 100%;
    "
  >
    <Toolbar
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
      style="border-bottom: 1px solid #ccc"
    />
    <Editor
      :defaultConfig="editorConfig"
      :mode="mode"
      v-model="html"
      style="height: 400px; overflow-y: hidden"
      @onCreated="handleCreated"
      @onChange="handleChange"
      @onDestroyed="handleDestroyed"
      @onFocus="handleFocus"
      @onBlur="handleBlur"
      @customAlert="customAlert"
      @customPaste="customPaste"
    />
  </div>
</template>
<script>
import Vue from "vue";
import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { uploadFileNew } from "../../api/files";
import { Loading } from "element-ui";
export default Vue.extend({
  name: "extendContentEditor",
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      mode: "default",
      html: "",
      toolbarConfig: {
        toolbarKeys: [
          "headerSelect",
          "blockquote",
          "clearStyle",
          "|",
          "bold",
          "underline",
          "italic",
          "color",
          "bgColor",
          "|",
          "fontSize",
          "fontFamily",
          "lineHeight",
          // "|",
          {
            key: "group-image", // 必填，要以 group 开头
            title: "上传图片", // 必填
            iconSvg: "",
            menuKeys: ["uploadImage"],
          },
          {
            key: "group-video",
            title: "上传视频",
            iconSvg: "",
            menuKeys: ["uploadVideo"],
          },
          "|",
          // "divider",
          // "emotion",
          "redo",
          "undo",
          // "codeBlock",
          "fullScreen",
        ],
      },
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          // 上传图片的配置
          uploadImage: {
            server: "/api/csias/file/uploadFileNew",
            fieldName: "wangeditor-uploaded-image",
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 5 * 1024 * 1024, // 1M
            // 最多可上传几个文件，默认为 100
            //maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ["image/*"],
            meta: {},
            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: true,
            headers: {
              "Cache-Control": "no-cache",
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
            },
            // 跨域是否传递 cookie ，默认为 false
            withCredentials: false,
            // 超时时间，默认为 10 秒
            timeout: 10 * 1000, // 5 秒
            async customUpload(file, insertFn) {
              // file 即选中的文件
              // 自己实现上传，并得到图片 url alt href
              // 最后插入图片
              const formData = new FormData();
              formData.append("file", file);
              formData.append("fileType", "image");
              const loading = Loading.service({
                lock: true,
                text: "文件上传中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.3)",
              });
              const res = await uploadFileNew(formData);
              if (res.code == 0 && res.success == true) {
                loading.close();
                insertFn(res.data);
              }
            },
          },
          // 上传视频的配置
          uploadVideo: {
            server: "/api/csias/file/uploadFileNew",
            fieldName: "wangeditor-uploaded-video",
            // 单个文件的最大体积限制，默认为 10M
            maxFileSize: 100 * 1024 * 1024, // 5M
            // 最多可上传几个文件，默认为 5
            maxNumberOfFiles: 5,
            // 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ["video/*"],
            meta: {},
            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: true,
            headers: {
              "Cache-Control": "no-cache",
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
            },
            // 跨域是否传递 cookie ，默认为 false
            withCredentials: false,
            // 超时时间，默认为 10 秒
            timeout: 30 * 1000, // 5 秒
            // 自定义上传
            async customUpload(file, insertFn) {
              // file 即选中的文件
              // 自己实现上传，并得到视频 url
              const formData = new FormData();
              formData.append("file", file);
              formData.append("fileType", "video");
              const loading = Loading.service({
                lock: true,
                text: "文件上传中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.3)",
              });
              const res = await uploadFileNew(formData);
              if (res.code == 0 && res.success == true) {
                loading.close();
                insertFn(res.data);
              }
            },
          },
        },
      },
    };
  },
  methods: {
    // 记录 editor 实例，重要！
    handleCreated(editor) {
      this.editor = Object.seal(editor);
    },
    handleChange(editor) {
      // console.info(editor.getHtml());
      this.$emit("funSuccess", editor.getHtml());
    },
    //销毁
    handleDestroyed(editor) {
      console.log("onDestroyed", editor);
    },
    handleFocus(editor) {
      console.log("onFocus", editor);
    },
    handleBlur(editor) {
      console.log("onBlur", editor);
    },
    customAlert(info, type) {
      window.alert(`customAlert in Vue demo\n${type}:\n${info}`);
    },
    customPaste(editor, event, callback) {
      console.log("ClipboardEvent 粘贴事件对象", event);
      event.preventDefault();
      callback(false);
    },
    insertText() {
      const editor = this.editor;
      if (this.editor == null) return;
      editor.insertText("hello world");
    },
    printHtml() {
      const editor = this.editor;
      if (this.editor == null) return;
      console.log(editor.getHtml());
    },
    disable() {
      const editor = this.editor;
      if (this.editor == null) return;
      editor.disable();
    },
    initData(context = " ", isEnable = 1) {
      if (this.editor == null) {
        setTimeout(() => {
          this.html = context || "";
          // this.editor.setHtml(this.html);
          if (isEnable == 1) {
            this.editor.enable(); //启用
          } else if (isEnable == 2) {
            this.editor.disable(); //禁用
          }
        }, 500);
      } else {
        this.html = context || "";
        // this.editor.setHtml(this.html);
        if (isEnable == 1) {
          this.editor.enable(); //启用
        } else if (isEnable == 2) {
          this.editor.disable(); //禁用
        }
      }
    },
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    setTimeout(() => {
      //   this.html = "<p>模拟 Ajax 异步设置内容 HTML</p>";
    }, 1500);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
});
</script>
